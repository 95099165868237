.menuContent {
  max-height: 300px;
  overflow-y: scroll;
  padding: 10px;
}

.menuItem {
  &:hover {
    color: var(--marketplaceColor);
    cursor: pointer;
  }
}
