@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-shrink: 0;
  padding: 0;
  height: 100%;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    /* padding: 0 24px; */
  }

  @media (--viewportLarge) {
    /* padding: 0 36px; */
  }
}

.logo {
  /* If height is increased, change link margins accordingly */
  /* NOTE: extremely wide logos may look bad. This will scale them to container of 24 x 200. */
  height: auto;
  width: auto;
  /* max-width: 188px; */
  object-fit: contain;
  object-position: center;
  /* margin: 18px 0; */

  @media (--viewportMedium) {
    /* margin: 24px 0; */
  }
}